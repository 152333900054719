export const userTypes = {
  Owner: "Owner",
  Manager: "Manager",
  Employee: "Employee",
  Vendor: "Vendor",
  GeneralManager: "GM",
  persoalAssistant: "PA",
  Company: "COMPANY",
  Task: "TASK",
  HrOfficer: "HROFFICER",
  Project: "PROJECT",
  addHrOfficer: "addHrOfficer",
  officer: "officer",
  HrManager: "HRMANAGER",
  all: "ALL",
  Self: "Self",
};

export const sidebarType = {
  CHAT: "chat",
  CALENDAR: "calendar",
  EMAIL: "email",
};

export const moduleType = {
  FOOTER: "FOOTER",
};
export const moduleTypes = {
  Profile: "profile",
  Managetask: "managetask",
  Contactprivate: "contactprivate",
  Contactshare: "contactshare",
  TaskReport: "taskreport",
  AttendanceReport: "attendancereport",
  performanceReport: "performancereport",
  workloadReport: "workloadreport",
  staffReport: "staffreport",
  viewCalendar: "viewcalendar",
  calendar: 'calendar'
};
