import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { eventActionType } from "../Utils/ApiConstants";
import { moduleType } from "../../components/Task/Components/constants";
import { useDispatch } from "react-redux";
import {
  setNotificationBody,
  setNotificationLogout,
} from "../../store/Reducer";

const NotificationTitleBody = ({
  title,
  body,
  detail,
  onNotificationClick,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const parsedData = JSON.parse(detail);
  console.log("parsedData", parsedData);
  const navigateTo = (data) => {
    const { actionType, taskId, documentId, leaveRequestId } = data;
    const stateObj = {
      isNotification: true,
    };

    if (taskId) {
      if (actionType === eventActionType?.DELETE_TASK) return;

      stateObj["taskId"] = taskId;

      if (data.moduleType === moduleType.SUBTASK) {
        return navigate("/subtask/view", { state: stateObj });
      }

      return navigate("/taskDetails", { state: stateObj });
    }

    switch (actionType) {
      case eventActionType.CREATE_EXPIRY:
        return navigate("/renewals/view", {
          state: { ...stateObj, renewalId: data?.renewalId },
        });
      case eventActionType.ADD_GROUP_MEMBER:
        return navigate("/message", {
          state: { ...stateObj, data: { ...data, _id: data?.groupId } },
        });
      case eventActionType.SEND_MESSAGE:
        const { groupId, chatId } = data;
        const isMessageRoute = location?.pathname === "/message";

        if (groupId) {
          return navigate("/message", {
            state: {
              ...stateObj,
              data: { isMessageRoute: isMessageRoute, ...data, _id: groupId },
            },
          });
        }

        return navigate("/message", {
          state: {
            ...stateObj,
            data: { isMessageRoute: isMessageRoute, ...data, _id: chatId },
          },
        });
      case eventActionType.SHARE_CONTACT:
        return navigate("/contactRepository/sharedListing", {
          state: documentId,
        });
      case eventActionType.SHARE_DOCUMENT:
        return navigate("/documentRepository/sharedWithMe/view", {
          state: documentId,
        });
      case eventActionType.VOICE_NOTE_SHARE:
        return navigate("/voiceNotes/sharedWithMe", {
          state: documentId,
        });
      case eventActionType.CONTACT_EDITED:
        return navigate("/contactRepository/privateListing/viewBusinessCard", {
          state: documentId,
        });

      case eventActionType.LEAVE_APPROVE_DISAPPROVE:
        return navigate("myLeaveRequests/view", {
          state: { leaveId: leaveRequestId },
        });
      case eventActionType.ADDED_LEAVE_REQUEST:
        return navigate("/manageRequest/leaveRequest");
      case eventActionType.EDIT_USER: {
        dispatch(setNotificationLogout(true));
        dispatch(
          setNotificationBody(
            JSON.stringify({
              title: "Profile updated",
              body: parsedData?.message,
            })
          )
        );
        setTimeout(() => {
          onNotificationClick(true);
        }, 500);
        return;
      }
      case eventActionType.SHARE_MOM:
        return navigate("/mom/sharedWithMe/view", {
          state: { eventid: documentId, isShared: true },
        });
      default:
        return;
    }
  };

  return (
    <div
      className="pt-2 pl-1 cursor-pointer notificationToastBody"
      onClick={() => navigateTo(parsedData)}
    >
      <h5>{title}</h5>
      <p>{body}</p>
    </div>
  );
};
export default NotificationTitleBody;
