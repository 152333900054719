import { Grid, Stack } from "@mui/material";
import moment from "moment";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import CompanyHeader from "../../common/CompanyHeader";
import { colors } from "../../common/Theme/Colors";
import { userTypes } from "../../common/users/userTypes";
import { checkModuleAccess, moduleTypes } from "../../common/Utils/commonFunction";
import {
  StyledCard,
  StyledTypography
} from "../../common/Utils/StyledComponents";
import { showToast } from "../../common/Utils/ToastMessage";
import {
  useCriticalTaskMutation,
  useGetManageTaskCollectionMutation,
  usePinTaskMutation
} from "../../request/Task";
import Translate from "../../translation/utils/translateFunction";
import { taskStatusType } from "../AddTask/constants";
import Searchbar from "../common/Searchbar";
import CompanyListing from "../ContactRepository/component";
import DateRangePicker from "../DateRangePicker";
import IconifyIcon from "../Icon";
import ListView from "../ListView";
import { moduleType } from "../Task/Components/constants";
import DropDownField from "../TextField/DropdownField";
import { getManagetTaskColumns } from "./column.data";
import { MarkCriticalAndPinTask } from "./Components/utils";
import { projectTypeOptions } from "./constants";

const ManageTask = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { selectedCompanyList, userData, isRtl } = useSelector(
    (state) => state.formanagement
  );

  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [taskListState, setTaskListState] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [lastPageNo, setLastPageNo] = useState(0);
  const [selectedPriority, setSelectedPriority] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [projectOptions] = useState(projectTypeOptions);
  const [selectedProject, setSelectedProject] = useState("ALL");
  const [manageTaskFilters, setManageTaskFilters] = useState([]);
  useEffect(() => {
    if (userData && userData?.masterData) {
      const { priority, taskStatus } = userData?.masterData;
      const updatedPriorities = priority?.map((item) => ({
        label: item,
        value: item,
      }));
      const updatedStatus = taskStatus?.map((item) => ({
        label: item,
        value: item,
      }));
      setPriorityOptions(updatedPriorities);
      setStatusOptions(updatedStatus);
    }
  }, [userData]);
  useEffect(() => {
    if (userData) {
      const data = userData?.moduleAccess?.find(
        (item) => Object.keys(item)[0] === moduleTypes.Managetask
      );
      data &&
        setManageTaskFilters(
          Object.values(data)[0]?.filter((item) => item?.isEnable)
        );
    }
  }, [userData]);
  const [
    fetchAllTask,
    {
      data: manageTaskDataList,
      isLoading: isLoadingManageTask,
      isError: isManageTaskError,
      error: manageTaskError,
    },
  ] = useGetManageTaskCollectionMutation();

  const [trigger, { data: pinTaskData }] = usePinTaskMutation({
    fixedCacheKey: "pinnedTask",
  });
  const [markCritical, { data: markCriticalData }] = useCriticalTaskMutation({
    fixedCacheKey: "criticalTask",
  });
  const clearFilter = () => {
    setCurrentPage(1);
    setSelectedProject("ALL");
    setSelectedPriority("");
    setSelectedStatus("");
    setDateRange([null, null]);
  };
  const bodyObj = useMemo(() => {
    const companyIdArray = [];
    selectedCompanyList?.length > 0 &&
      selectedCompanyList.map((idItem) => companyIdArray.push(idItem.value));
    return companyIdArray?.length > 0
      ? sortBy !== null && sortBy !== undefined
        ? {
          companyId: companyIdArray,
          searchText: searchText,
          projectId: selectedProject,
          priority: selectedPriority ? [selectedPriority] : [],
          status: selectedStatus ? [selectedStatus] : [],
          from: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
          to: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
          pageNo: currentPage,
          sortCoulmn: selectedColumn,
          sortDirection: sortBy ? 1 : -1,
        }
        : {
          companyId: companyIdArray,
          searchText: searchText,
          projectId: selectedProject,
          priority: selectedPriority ? [selectedPriority] : [],
          status: selectedStatus ? [selectedStatus] : [],
          from: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
          to: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
          pageNo: currentPage,
        }
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    searchText,
    selectedProject,
    selectedStatus,
    selectedPriority,
    sortBy,
    selectedCompanyList,
    dateRange,
  ]);

  const showFilters = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    setTaskListState([]);
    if (selectedCompanyList?.length > 0) {
      sortBy === null && setIsLoading(true);
      if (bodyObj?.searchText?.length) {
        debouncedSearch();
      } else {
        sortBy !== undefined && fetchAllTask(bodyObj);
      }
    } else {
      showToast({
        type: "info",
        message: Translate("manageTask:selectCompanyMessage"),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyObj, pinTaskData, markCriticalData]);

  const debouncedSearch = useDebouncedCallback(() => {
    sortBy !== undefined && fetchAllTask(bodyObj);
  }, 500);

  useEffect(() => {
    if (manageTaskDataList?.data.nodes) {
      const { pageInfo, nodes } = manageTaskDataList.data;
      setTaskListState(nodes);
      setLastPageNo(pageInfo.lastPageNo);
      setIsLoading(false);
    }
    if (manageTaskError && isManageTaskError) {
      setIsLoading(false);
      manageTaskError?.originalStatus !== 500 &&
        showToast({
          type: "error",
          message: JSON.stringify(manageTaskError.data),
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageTaskDataList, isManageTaskError]);

  const debounced = useDebouncedCallback((columnName) => {
    selectedColumn !== columnName ? setSortBy(true) : setSortBy(!sortBy);
  }, 500);

  const getSort = (columnName = "") => {
    setIsLoading(true);
    selectedColumn !== columnName && setSortBy(undefined);
    debounced(columnName);
    setSelectedColumn(columnName);
  };
  const customActionCellRender = (params) => {
    const isCompleted = params?.row?.taskStatus === taskStatusType?.COMPLETED;

    const showCriticalFlag = isCompleted
      ? false
      : params?.row?.userDetails?.some(
        (details) => details?._id === userData?._id
      );

    return (
      <>
        <div className="headerMargin">
          <a
            href="/#"
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false">
            <i className="fa fa-ellipsis-v manageIconColor" />
          </a>
          <div className="dropdown-menu dropdownAlignment dropdownIconMenu">
            <MarkCriticalAndPinTask
              taskId={params?.row?.taskId || params?.row?._id}
              showCriticalFlag={showCriticalFlag}
              hasFlagged={params?.row?.hasFlagged}
              isCompleted={isCompleted}
              hasPinned={params?.row?.hasPinned}
              markAsCritical={markCritical}
              pinTask={trigger}
            />

            <div
              className="manageIconColor ml-3 cursor-pointer dropdown-item"
              onClick={() =>
                navigate(
                  params?.row?.moduleType === moduleType.SUBTASK
                    ? "/subtask/view"
                    : "/taskDetails",
                  {
                    state: {
                      taskId: params?.row?.taskId,
                      userDetails: params?.row?.userDetails,
                      isManageTask: true,
                      navigateFrom: "/manageTask",
                    },
                  }
                )
              }>
              <span className={`manageIconColor fa fa-eye `} />
              {Translate("manageTask:viewTask")}
            </div>
          </div>
        </div>
      </>
    );
  };
  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const setFilterValue = (item, placeholder) => {
    setCurrentPage(1);
    switch (placeholder) {
      case Translate("manageTask:statusPlaceHolder"):
        {
          item.target.value !== placeholder
            ? setSelectedStatus(item.target.value)
            : setSelectedStatus("");
        }
        break;
      case Translate("addTask:projectPlaceholder"):
        {
          item.target.value !== placeholder
            ? setSelectedProject(item.target.value)
            : setSelectedProject("ALL");
        }
        break;
      case Translate("manageTask:priorityPlaceHolder"):
        {
          item.target.value !== placeholder
            ? setSelectedPriority(item.target.value)
            : setSelectedPriority("");
        }
        break;
    }
  };
  const handleSearchText = (value) => {
    selectedCompanyList?.length > 0 && value?.length > 0 && setCurrentPage(1);
    setSearchText(value);
  };
  console.log("break1:", userData);

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${fixNavbar ? "marginTop" : ""
        } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="card cardHeight">
              <div className="row">
                <div className="col-12">
                  <div className="card-header fixedHeight">
                    <Stack style={{ width: "18%" }}>
                      <CompanyHeader
                        setShowModal={setShowModal}
                        className="mr-1"
                      />
                    </Stack>

                    <div
                      className={`card-options ${showFilter && "manageTaskFilterDiv"
                        }`}>
                      <div className="sort_stat commentTextField">
                        {showFilter ? (
                          <div className="row" style={{ width: "75%" }}>
                            <div
                              className="col-lg-2 col-md-2 col-sm-4"
                              style={{ marginTop: "9px" }}>
                              <StyledTypography
                                sx={{ color: colors.primary_009 }}
                                component="span">
                                {Translate("addTask:project")}
                              </StyledTypography>
                              <div className="multiselect_div managetaskCustomFilters">
                                <div className="form-group cursor-pointer">
                                  <DropDownField
                                    options={projectOptions}
                                    className={
                                      "form-control textFieldHeight cursor-pointer"
                                    }
                                    placeholder={Translate(
                                      "addTask:projectPlaceholder"
                                    )}
                                    onChange={(item) =>
                                      setFilterValue(
                                        item,
                                        Translate("addTask:projectPlaceholder")
                                      )
                                    }
                                    value={selectedProject}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          width: "auto",
                                          overflowX: "auto",
                                        },
                                      },
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                      },
                                      transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                      },
                                    }}
                                    customMenuItemStyle={{ width: "100%" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-lg-2 col-md-2 col-sm-4"
                              style={{ marginTop: "9px" }}>
                              <StyledTypography
                                sx={{ color: colors.primary_009 }}
                                component="span">
                                {Translate("manageTask:priority")}
                              </StyledTypography>
                              <div className="managetaskCustomFilters">
                                <div className="form-group cursor-pointer">
                                  <DropDownField
                                    options={priorityOptions}
                                    className={
                                      "form-control textFieldHeight cursor-pointer"
                                    }
                                    placeholder={Translate(
                                      "manageTask:priorityPlaceHolder"
                                    )}
                                    onChange={(item) =>
                                      setFilterValue(
                                        item,
                                        Translate(
                                          "manageTask:priorityPlaceHolder"
                                        )
                                      )
                                    }
                                    value={selectedPriority}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-lg-3 col-md-3 col-sm-4"
                              style={{ marginTop: "9px" }}>
                              <StyledTypography
                                sx={{ color: colors.primary_009 }}
                                component="span">
                                {Translate("manageTask:status")}
                              </StyledTypography>
                              <div className="managetaskCustomFilters">
                                <div className="form-group cursor-pointer">
                                  <DropDownField
                                    options={statusOptions}
                                    className={
                                      "form-control textFieldHeight cursor-pointer"
                                    }
                                    placeholder={Translate(
                                      "manageTask:statusPlaceHolder"
                                    )}
                                    onChange={(item) =>
                                      setFilterValue(
                                        item,
                                        Translate(
                                          "manageTask:statusPlaceHolder"
                                        )
                                      )
                                    }
                                    value={selectedStatus}
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-lg-3 col-md-3 col-sm-6 dateRangeBox"
                              style={{ marginTop: "9px" }}>
                              <StyledTypography
                                sx={{ color: colors.primary_009 }}
                                component="span">
                                {Translate("manageTask:date")}
                              </StyledTypography>
                              <div style={{ width: "90%" }} id="manageTask">
                                <DateRangePicker
                                  setDateRange={(value) => {
                                    setCurrentPage(1);
                                    setDateRange(value);
                                  }}
                                  startDate={startDate}
                                  endDate={endDate}
                                  className="dateRangeFormControl"
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div
                          className="filterIconBox headerMargin mx-2"
                          style={{
                            width: "100%",
                            textAlign: "end",
                          }}
                          onClick={() => {
                            showFilter && clearFilter();
                            showFilters(!showFilter);
                          }}>
                          {showFilter ? (
                            <span
                              data-toggle="tooltip"
                              title={Translate("common:clearFilterToolTip")}>
                              <IconifyIcon
                                icon="mdi:filter-off"
                                width="19"
                                className="dateRangeFilterIcon"
                              />
                            </span>
                          ) : (
                            <span
                              data-toggle="tooltip"
                              title={Translate("common:showFilterToolTip")}>
                              <IconifyIcon
                                icon="fa:filter"
                                width="14"
                                className="dateRangeFilterIcon"
                              />
                            </span>
                          )}
                        </div>
                        <form className="headerMargin">
                          <div className="input-group">
                            <Searchbar
                              handleSearchText={handleSearchText}
                              setSearch={setSearchText}
                            />
                          </div>
                        </form>
                        <form className="headerMargin ml-3">
                          {checkModuleAccess(
                            userData?.add,
                            userTypes?.Task
                          ) && (
                              <div className="header-action pull-right">
                                <button
                                  type="button"
                                  className="btn btn-primary py-2"
                                  onClick={() => navigate("/addTask")}>
                                  <i className="fe fe-plus" />
                                  <StyledTypography
                                    className="mx-1"
                                    component="span">
                                    {Translate("common:add")}
                                  </StyledTypography>
                                </button>
                              </div>
                            )}
                        </form>
                        {!!manageTaskFilters?.length && (
                          <div className="d-flex ml-2">
                            <div className="headerMargin">
                              <a
                                href="/#"
                                className="nav-link dropdown-toggle"
                                data-toggle="dropdown"
                                role="button"
                                aria-haspopup="true"
                                aria-expanded="false">
                                <i className="fa fa-ellipsis-v manageIconColor" />
                              </a>

                              <div className="dropdown-menu dropdownAlignment">
                                {manageTaskFilters.map((filterItem, index) => (
                                  <Fragment key={index}>
                                    <div
                                      className="cursor-pointer"
                                      onClick={() =>
                                        navigate(`/${filterItem?.navigation}`)
                                      }>
                                      <a className="dropdown-item">
                                        {Translate(
                                          `manageTask:${filterItem?.label}`
                                        )}
                                      </a>
                                    </div>
                                    {index !== manageTaskFilters?.length && (
                                      <div className="dropdown-divider" />
                                    )}
                                  </Fragment>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ListView
                  columns={getManagetTaskColumns(
                    customSrCellRender,
                    customActionCellRender
                  )}
                  rows={taskListState}
                  autoHeight
                  sortingMode="server"
                  sortBy={sortBy}
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isLoading || isLoadingManageTask}
                  showLoader={isLoading || isLoadingManageTask}
                  totalPageCount={lastPageNo}
                  onSortModelChange={(data) =>
                    data?.length && getSort(data[0]?.field)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>

      <CompanyListing
        handleModal={(value) => {
          setCurrentPage(1);
          setShowModal(value);
          setSelectedProject("ALL");
        }}
        showModal={showModal}
        selectedCompanyList={selectedCompanyList}
      />
    </Grid>
  );
};
export default ManageTask;
